import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faSquare } from '@fortawesome/free-regular-svg-icons'

const Aboutus = ({ location }) => {

  const Hr = () => <hr className="dots-hr mt-3 mb-4" />
  const Circle = () => <FontAwesomeIcon icon={faCircle} />
  const Square = () => <FontAwesomeIcon icon={faSquare} />

  return (
    <Layout location={location} title="会社概要" >
      <SEO
        title="会社概要"
        description="株式会社グローバルワイズの会社概要をご覧いただけます。"
      />

      <div className="page-head">
        <Container expand="lg">
          <h2>会社概要</h2>
          <p>株式会社グローバルワイズの会社概要をご覧いただけます。</p>
        </Container>
        <div className="bottom-border" />
      </div>

      <Container expand="lg">
        <Row>
          <Col lg={2}><p><Circle /> <strong>会社名</strong></p></Col>
          <Col lg={10}><p>株式会社グローバルワイズ（GLOBAL WISE Co.）</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>役員</strong></p></Col>
          <Col lg={10}>
            <ul className="half-list">
              <p>取締役会長　　　伊原 栄⼀</p>
              <p>代表取締役社長　松原 真那武</p>
              <p>技術担当取締役　稲野 清治</p>
              <p>取締役　　　　　栢森 雅勝</p>
              <p>取締役　　　　　田村 拓也</p>
              <p>取締役　　　　　尾関 貴夫</p>
              <p>監査役　　　　　西尾 光生</p>
            </ul>
          </Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>設⽴年⽉⽇</strong></p></Col>
          <Col lg={10}><p>1997年11⽉19⽇</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>資本⾦</strong></p></Col>
          <Col lg={10}><p>1億円（2016年2⽉末時点）</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>社員数</strong></p></Col>
          <Col lg={10}><p>50名（2024年4⽉時点）</p></Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><Circle /> <strong>事業内容</strong></p></Col>
          <Col lg={10}>
            <ul className="half-list">
              <li><Square />　EDIシステム開発</li>
              <li><Square />　IoTシステム開発</li>
              <li><Square />　AWS導⼊⽀援</li>
              <li><Square />　業務アプリケーション開発</li>
              <li><Square />　システム保守・運⽤</li>
              <li><Square />　コンピュータ機器販売・保守・輸出⼊</li>
            </ul>
          </Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><FontAwesomeIcon icon={faCircle} /> <strong>取引先</strong></p></Col>
          <Col lg={10}>
            <ul className="half-list">
              <li><Square />　アイシン・インフォテックス株式会社</li>
              <li><Square />　井村屋フーズ株式会社</li>
              <li><Square />　NRIセキュアテクノロジーズ株式会社</li>
              <li><Square />　株式会社コジマ</li>
              <li><Square />　小島プレス工業株式会社グループ</li>
              <li><Square />　株式会社JERA</li>
              <li><Square />　シティ・グループ・サービスジャパン合同会社</li>
              <li><Square />　株式会社ジャパンインベストメントアドバイザー</li>
              <li><Square />　ダイコク電機株式会社</li>
              <li><Square />　株式会社タダノ</li>
              <li><Square />　株式会社中電シーティーアイ</li>
              <li><Square />　中部電力株式会社</li>
              <li><Square />　トピックス株式会社</li>
              <li><Square />　株式会社トヨタシステムズ</li>
              <li><Square />　トヨタ紡織株式会社</li>
              <li><Square />　豊田通商システムズ株式会社</li>
              <li><Square />　日進工業株式会社</li>
              <li><Square />　日東工業株式会社</li>
              <li><Square />　マネックス証券株式会社</li>
              <li><Square />　宮地電機株式会社</li>
              <li><Square />　未来工業株式会社</li>
              <li><Square />　矢崎部品株式会社</li>
              <li><Square />　株式会社UACJ金属加工</li>
              <li><Square />　山宗株式会社</li>
            </ul>
          </Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><FontAwesomeIcon icon={faCircle} /> <strong>関連会社</strong></p></Col>
          <Col lg={10}>
            <ul className="half-list">
              <li><Square />　ダイコク電機株式会社</li>
            </ul>
          </Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><FontAwesomeIcon icon={faCircle} /> <strong>加⼊団体</strong></p></Col>
          <Col lg={10}>
            <ul className="half-list">
              <li><Square />　⼀般社団法⼈クラウドサービス推進機構(CSPA) </li>
              <li><Square />　⼀般社団法⼈ソフトウェア協会(SAJ)</li>
              <li><Square />　⼀般社団法⼈サプライチェーン情報基盤研究会(SIPS) </li>
              <li><Square />　⼀般社団法⼈持続可能なモノづくり・⼈づくり⽀援協会(ESD21) </li>
              <li><Square />　⼀般社団法⼈運輸デジタルビジネス協議会(TDBC) </li>
              <li><Square />　サプライチェーン・サイバーセキュリティ・コンソーシアム（SC3） </li>
              <li><Square />　特定⾮営利活動法⼈ＩＴコーディネータ協会(ITCA) </li>
              <li><Square />　つなぐITコンソーシアム</li>
              <li><Square />　⼀般社団法⼈愛知県情報サービス産業協会(AiA)</li>
              <li><Square />　IoT推進コンソーシアム</li>
              <li><Square />　あいちロボット産業クラスター推進協議会 </li>
              <li><Square />　ユーオス・グループ(UOS) </li>
              <li><Square />　名古屋商⼯会議所 正会員</li>
              <li><Square />　他（敬称略・順不同）</li>
            </ul>
          </Col>
          <Col lg={12}><Hr /></Col>

          <Col lg={2}><p><FontAwesomeIcon icon={faCircle} /> <strong>販売パートナー</strong></p></Col>
          <Col lg={10}>
            <ul className="half-list">
              <li><Square />　APN(AWS Partner Network)セレクトコンサルティングパートナー</li>
              <li><Square />　株式会社応研　販売パートナー</li>
              <li><Square />　株式会社オービックビジネスコンサルタント　奉行販売パートナー</li>
              <li><Square />　株式会社エフ・ビー・エス　販売パートナー</li>
              <li><Square />　株式会社ラクス　販売パートナー</li>
              <li><Square />　他（敬称略・順不同）</li>
            </ul>
          </Col>
          <Col lg={12}><Hr /></Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Aboutus